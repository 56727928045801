.spinner-border {
  position: absolute;
  top: 50%;
  left: 50%;
}

.preloader__wrap {
  position: relative;
  // opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.856);
  z-index: 10000;
  overflow: hidden;
}