.toolbar-container {
  margin-bottom: 1rem;
  padding-left: 0 !important;
}

.filter-practice, .filter-provider, .filter-patient {
  width: 15rem;
}

.filter-uid {
  width: 21rem;
}

.filter-email {
  width: 15rem;
}

.filter-date {
  width: 10rem;
}

.filter-id,
.filter-external-id,
.filter-smylen-id {
  width: 7rem;
}

.filter-id,
.filter-uid,
.filter-external-id,
.filter-smylen-id,
.filter-user-name,
.filter-patient-name,
.filter-provider-name,
.filter-practice-name,
.filter-email,
.filter-date {
  background-color: hsl(0,0%,100%);
  border-color: hsl(0,0%,80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;  
  cursor: default;
  box-sizing: border-box;
  padding: 6px 8px;

  &:focus {
    border-color: #4684FF;
    box-shadow: 0 0 0 1px #4684FF;
  }

  &:hover:not(:focus) {
    border-color: hsl(0,0%,70%);
  }

  &::-webkit-search-cancel-button{
    background-color: gray;
  }
}

.filter-toggle {
  padding: 0;

  .btn-light {
    background-color: hsl(0,0%,100%);
    border-color: hsl(0,0%,80%) !important;
    border-radius: 4px !important;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    box-sizing: border-box;
  
    &.focus {
      border-color: #4684FF !important;
      box-shadow: 0 0 0 1px #4684FF !important;
    }

    &:hover:not(.focus) {
      border-color: hsl(0,0%,70%) !important;
    }
  }
}

.react-datepicker__close-icon {
  padding-right: 18px;
  
  &::after {
    cursor: pointer;
    background-color: transparent;
    color: black;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    padding: 2px;
    font-size: 14px;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: "X";
    font-weight: bold;
    font-family: cursive;
  }
}
