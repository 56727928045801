.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.react-datepicker__close-icon {
  padding-right: 18px;
  margin-top: .5rem;
  height: 85%;
}

.tooltip-inner {
  padding: 0 .2rem;
  font-size: .8rem;
}

.btn-primary,
.btn-success,
.btn-danger {
  path {
    color: white !important;
  }
}
