.reset {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  &__text {
    text-align: center;
    font-size: 20px;
  }
  .alert {
    margin-top: 15px;
  }
}