@import '../../assets/scss/_variables';

.profile-form {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: $shadow;
  form {
    width: 100%;
    .col-form-label {
      text-align: right;
    }
    .label {
      text-align: right;
    }
  }
}