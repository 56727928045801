.input {
  position: relative;
  &__error-message {
    color: red;
    font-size: 0.7rem;
    visibility: hidden;
    height: 0;
  }
  &_error {
    .input__error-message {
      visibility: visible
    }
  }
}