  $breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

$breakpoint-mobile-start: 320px;
$breakpoint-mobile-end: 767px;
$breakpoint-tablet-start: $breakpoint-mobile-end + 1px;
$breakpoint-tablet-end: 1024px;
$breakpoint-desktop-start: $breakpoint-tablet-end + 1px;

$header-color: #3d5170;
$bg-color: #f5f6f8;
$nav-color: #3f4d67;
$primary-color: #007bff;
$secondary-color: #5a6169;
$info-color: #00b8d8;
$success-color: #17c671;
$warning-color: #ffb400;
$danger-color: #c4183c;
$btn-fz: 0.85rem;
$btn-lh: 1.7;
$input-fz: .8125rem;
$input-lh: 1.5;
$label-lh: 1;
$table-color: #5a6169;
$shadow: 0 2px 0 rgba(90,97,105,.11), 0 4px 8px rgba(90,97,105,.12), 0 10px 10px rgba(90,97,105,.06), 0 7px 70px rgba(90,97,105,.1);
